import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {

	data: function () {
		return {
			units: [],
			imagesForModal: [],
			showUnits: false
		};
	},
	beforeMount: function () {
		let unitgroup;
		let el = document.querySelector("[data-unitgroup]");
		if (el) {
			unitgroup = parseInt(el.getAttribute('data-unitgroup'), 10);
		} else {
			//console.log(' is not found..................');
		}

		//unitgroup = 20733;

		//console.log('unitgroup', unitgroup);
		if (!isNaN(unitgroup)) {
			this.showUnits = true;
			//console.log('Unit Group ist eine Zahl');
		} else {
			this.showUnits = false;
			//console.log('Unit Group ist keine Zahl!');
		}

		if (this.showUnits) {

			var results = this.$el.getElementsByClassName('unit-list-section');
			if (results.length) {
				this.resultFields = findResultFields(results[0].innerHTML, this.$el);
			}




			var req = {
				fields: this.resultFields,
				filter: {
					unitgroupid: unitgroup
				},
				sorting: 'random',
				max: 6
			};

			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;
				});
		}


	},

	mounted: function () {
		/*this.$nextTick(function () {
			window.addEventListener('resize', this.handleResize);
		});*/
	},

	updated: function () {
		//this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();


	},
	beforeDestroy: function () {
		//window.removeEventListener('resize', this.handleResize);
	},
	methods: {

		handleResize: function () {
			//this.applySlider();

		},
		getUnitSearchTarget: function (id) {
			return 'unit_' + id;
		},


	},
	filters: {
		formatfeedback: function (value) {
			if (!value) return '';
			if (Number.isInteger(value)) return value.toFixed();
			return (value.toFixed(1).replace('.', ','));
		}
	}
};